<template>
  <button
    data-test="ist-button"
    class="ist-button"
    :disabled="isDisabled"
    :class="[
      variant,
      buttonClass,
      { rounded: rounded, elevated: elevated, round: round, flat: flat },
    ]"
    @pointerdown="handlePointDown"
    @pointerup="handlePointUp"
  >
    <q-spinner v-if="loading" class="loading-btn-spinner" size="24px" />
    <div v-if="iconName" class="ist-button-icon">
      <IstIcon :name="iconName" :size="iconSize" />
    </div>
    <div v-if="$slots.default && buttonStyle !== 'fab'" class="ist-button-content capitalize">
      <slot></slot>
    </div>
  </button>
</template>

<script setup lang="ts">
import { BUTTON_SIZES } from '@/assets/template';
import { computed, ref } from 'vue';

type ButtonStyle = 'fab' | 'default' | 'showmore' | 'outlined';
type ButtonSize = 'sm' | 'md' | 'lg' | 'xl';
type Variant = 'neutral' | 'default' | 'danger' | 'outlined';
interface ButtonProps {
  buttonStyle?: ButtonStyle;
  iconName?: string;
  size?: ButtonSize;
  ripple?: boolean;
  rounded?: boolean;
  elevated?: boolean;
  loading?: boolean;
  disable?: boolean;
  variant?: Variant;
  round?: boolean;
  width?: string;
  flat?: boolean;
  dense?: boolean;
}

const props = defineProps<ButtonProps>();

const isDisabled = computed(() => {
  return props.disable || props.loading;
});

const isPressed = ref(false);

const defaultSize = computed<ButtonSize>(() => {
  return props.size ? props.size : 'md';
});

const iconSize = computed(() => {
  return BUTTON_SIZES[defaultSize.value] / 1.5 + 'px';
});
const buttonSize = computed((): string => {
  return BUTTON_SIZES[defaultSize.value] + 'px';
});

const buttonWidth = computed((): string => {
  return props.width || `${BUTTON_SIZES[defaultSize.value] * 3}px`;
});

const fontSize = computed(() => {
  switch (props.size) {
    case 'sm':
      return '0.8rem';
    case 'md':
      return '0.9rem';
    case 'lg':
      return '1rem';
    case 'xl':
      return '1rem';
  }
  return '0.9rem';
});

const handlePointDown = () => {
  isPressed.value = true;
};
const handlePointUp = () => {
  isPressed.value = false;
};

const buttonClass = computed(() => {
  return props.buttonStyle ? props.buttonStyle : 'default';
});
</script>

<style scoped lang="scss">
button {
  border: none;
  padding: 0;
  font-weight: 500;
  min-height: 0;
}
.ist-button {
  cursor: pointer;
  background-color: $ist-button-primary-bg;
  color: $ist-color-white;
  transition: opacity 0.3s ease;
  display: flex;
}
.ist-button:hover {
  &:not([disabled]) {
    opacity: 0.9;
  }
}

.ist-button-content {
  margin: auto;
  font-size: v-bind(fontSize);
  text-transform: uppercase;
}

.ist-button-icon {
  display: inline-flex;
  margin: auto;
  text-align: center;
  align-items: center;
}
.fab {
  width: v-bind(buttonSize);
  height: v-bind(buttonSize);
  font-size: 10px;
  border: none;
  border-radius: 100%;
}

.loading-btn-spinner {
  margin: auto 0;
}
.showmore {
  background-color: transparent;
  color: $ist-color-ist;
  width: v-bind(buttonSize);
  height: v-bind(buttonSize);
  border: none;
  border-radius: 100%;
  margin-left: auto;
}
.default {
  min-height: v-bind(buttonSize);
  min-width: v-bind(buttonWidth);
  display: inline-flex;
  flex-direction: row;
  gap: 8px;
  padding: 0 16px;
}
.rounded {
  border-radius: 20px;
}

.elevated {
  box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f;
}

.danger {
  background-color: $ist-color-red;
  color: $ist-color-white;
}

.success {
  background-color: $ist-color-green-dark;
}
.neutral {
  background-color: $ist-color-white;
  border: 1px solid $ist-color-blue-dark;
  color: $ist-color-blue-dark;
  transition: all 300ms ease-in-out;
}

.round {
  min-width: v-bind(buttonSize);
  width: v-bind(buttonSize);
  height: v-bind(buttonSize);
  font-size: 10px;
  border: none;
  border-radius: 100%;
  padding: 0;
}
.flat {
  background-color: transparent;
  color: $ist-color-ist;
  width: v-bind(buttonSize);
  height: v-bind(buttonSize);
  border: none;
  border-radius: 100%;
  padding: 0;
  transition: all 300ms ease-in-out;
  &:hover {
    background-color: $ist-color-grey-light;
  }
}
</style>
