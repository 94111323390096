<template>
  <div data-test="main-view">
    <router-view></router-view>
    <modals-container></modals-container>
    <IstDialog />
  </div>
</template>

<script lang="ts" setup>
import IstDialog from '@/components/IstDialog.vue';
import mutations from './graphql/operations/';

// This is only used in E2E cypress tests

onMounted(() => {
  if (window.window.localStorage['e2eToken']) {
    window.mutations = mutations;
  }
});
</script>

<style lang="scss">
#app {
  font-family: 'Libre Franklin', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $ist-color-ist-xdark;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: $ist-color-ist-xdark;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
#userloading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
#userloading p {
  width: 450px;
  max-width: 90%;
  height: 80px;
  background-color: $ist-color-blue;
  color: #fff;
  border-radius: 10px;
  transform: translate(0, -50%);
  vertical-align: middle;
  line-height: 80px;
}
</style>
