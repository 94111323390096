import {
  TilbudsmedarbejderBarnMutationModelInput,
  UpdateMedarbejderBarnMutation,
  UpdateMedarbejderBarnMutationVariables,
} from '@/generated/graphql';
import { apolloClient } from '@/graphql/apolloClient';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { updateMedarbejderBarn } from '@/graphql/documents/mutations/barn/updateMedarbejderBarn.graphql';

export const useUpdateMedarbejderBarnMutation = () => {
  provideApolloClient(apolloClient);
  const { mutate, loading } = useMutation<
    UpdateMedarbejderBarnMutation,
    UpdateMedarbejderBarnMutationVariables
  >(updateMedarbejderBarn);

  const submitUpdateMedarbejderBarnMutations = async (
    tilknytningsInput: TilbudsmedarbejderBarnMutationModelInput[],
    queries?: string[],
  ): Promise<boolean> => {
    for (const input of tilknytningsInput) {
      try {
        await mutate({ input }, { refetchQueries: queries });
      } catch (error) {
        return false;
      }
    }
    return true;
  };

  return { submitUpdateMedarbejderBarnMutations, loading };
};
