function getTitle(vm): string | undefined {
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title;
  }
  return undefined;
}

export default {
  created(): void {
    const title: string | undefined = getTitle(this);
    if (title) {
      document.title = title;
    }
  },
};
