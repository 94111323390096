import { apolloClient } from '@/graphql/apolloClient';
import { ApolloError } from '@apollo/client/errors';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';

import { aktiverTilbudsmedarbejderAdmin } from '@/graphql/documents/userQueries/aktiverTilbudsmedarbejderMutation.graphql';
import {
  AktiverTilbudsmedarbejderAdminMutation,
  AktiverTilbudsmedarbejderAdminMutationVariables,
} from '@/generated/graphqlSubcriptionTypes';

// GET
export const useAktiverTilbudsmedarbejderMutation = () => {
  // composables, providers and refs
  provideApolloClient(apolloClient);
  const done = ref(false);

  // mutation composable
  const { mutate, loading } = useMutation<
    AktiverTilbudsmedarbejderAdminMutation,
    AktiverTilbudsmedarbejderAdminMutationVariables
  >(aktiverTilbudsmedarbejderAdmin, { context: { clientName: 'client' } });

  type AktiveTilbudsAdmin =
    AktiverTilbudsmedarbejderAdminMutation['aktiverTilbudsmedarbejderAdmin'];
  // promise to return in composable
  const submitCode = async (
    input: AktiverTilbudsmedarbejderAdminMutationVariables,
    queries: string[],
  ): Promise<AktiveTilbudsAdmin | ApolloError> => {
    return mutate(input, { refetchQueries: queries })
      .then((data) => {
        return data?.data?.aktiverTilbudsmedarbejderAdmin;
      })
      .catch((error) => {
        throw error;
      });
  };
  // returns from composable
  return { submitCode, loading, done };
};
