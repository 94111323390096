import { DirectiveBinding, ObjectDirective, RendererElement, RendererNode, VNode } from 'vue';
import { useSecurityStore } from '../store-index';

type CallbackType = () => void;

//create v-can directive
export const canDirective: ObjectDirective<HTMLElement, CallbackType> = {
  mounted(
    el: HTMLElement,
    binding: DirectiveBinding,
    vnode: VNode<RendererNode, RendererElement, { [key: string]: any }>,
  ) {
    let canresult = false;
    let action;
    let subject = '';
    let expectResult = true;
    if (binding.arg) {
      // ex. v-can:NOT.see="'menu'"
      if (binding.arg.toLowerCase() == 'not') {
        expectResult = false;
      } else {
        // eslint-disable-next-line no-console
        console.warn('v-can: unknown argument =' + binding.arg, { binding });
      }
    }
    if (binding.modifiers) {
      // ex. v-can:see.menu
      const modifiers = Object.getOwnPropertyNames(binding.modifiers);
      action = modifiers[0];
      subject = modifiers[1];
      if (!subject) {
        subject = binding.value;
      }
    }
    if (!action || action == '') {
      // eslint-disable-next-line no-console
      console.warn(
        'v-can: action is missing on ' + binding.instance?.$options.__name ||
          binding.instance?.$options._componentTag,
        { binding },
      );
    } else if (!subject || subject == '') {
      // eslint-disable-next-line no-console
      console.warn(
        'v-can: subject is missing on ' + binding.instance?.$options.__name ||
          binding.instance?.$options._componentTag,
        { binding },
      );
    } else {
      const { can } = useSecurityStore();
      canresult = can(action, subject);
    }
    if (canresult != expectResult) {
      if (vnode.el) {
        vnode.el.style.display = 'none';
      }
    }
  },
};
