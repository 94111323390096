import { Promiser } from '@/utils/promiseUtils';
import { reactive } from 'vue';

interface DialogState {
  type: string;
  active: boolean;
  message: string;
  inputModel: string;
  title: string;
  okText: string;
  cancelText?: string;
  hasInput?: boolean;
}
const dialogState = reactive<DialogState>({
  active: false,
  inputModel: '',
  cancelText: '',
  message: '',
  okText: '',
  title: '',
  type: 'alert',
});

let dialogPromise;
// WORK IN PROGRESS ON THIS ONE.
// Et spædt forsøg på at udnytte Teleport i Vue til at "injecte" en mini-modal i dom'en i body, med strongly/fully typed callbacks/promises.
export const useIstDialog = () => {
  interface DialogContent {
    title: string;
    content: string;
    hasInput?: boolean;
    okText?: string;
    cancelText?: string;
  }
  const open = async <T>(content: DialogContent) => {
    dialogState.type = 'alert';
    dialogState.message = content.content;
    dialogState.title = content.title;
    dialogState.inputModel = '';
    dialogState.hasInput = content.hasInput;
    dialogState.okText = content.okText || 'Ok';
    dialogState.cancelText = content.cancelText;
    dialogState.active = true;
    dialogPromise = new Promiser<T>();
    return dialogPromise.promise;
  };

  const reset = () => {
    dialogState.type = 'alert';
    dialogState.message = '';
    dialogState.active = false;
  };

  const dialog = {
    title(t: string) {
      dialogState.title = t;
    },
    hasInputField(input: true) {
      dialogState.hasInput = input;
    },

    okText(t: string) {
      dialogState.okText = t;
    },
    cancelText(t: string) {
      dialogState.cancelText = t;
    },
    cancel() {
      dialogPromise.resolve(false);
      reset();
    },
    confirm() {
      const returnValue = dialogState.inputModel.length > 0 ? dialogState.inputModel : true;
      dialogPromise.resolve(returnValue);
      reset();
    },
    prompt(content: DialogContent) {
      return open(content);
    },
  };

  return { dialog, dialogState };
};
