import { IModule } from '../security/models/module-model';

export default <IModule>{
  routes: [
    {
      path: '/kontrol',
      name: 'kontroller',
      meta: {
        title: 'Kontroloversigt',
        requiresAuth: true,
      },
      component: () => import('./KontrollerOversigt.vue'),
    },
    {
      path: 'kontrol/:id',
      name: 'kontrol',
      component: () => import('./KontrolResultView/KontrolResultView.vue'),
      meta: {
        title: 'Kontrol',
        breadCrumb() {
          return [
            {
              text: 'Kontroloversigt',
              to: { name: 'kontroller' },
            },
            { text: 'Kontrol' },
          ];
        },
      },
    },
  ],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  menu: [
    {
      title: 'Datakontrol',
      name: 'datakontrol',
      entryPointUrl: '/kontrol',
      iconName: 'mdi-check-circle-outline',
      priority: 101,
      userRightNames: ['MenupunktDatakontrolSe'],
    },
  ],
};
