import {
  FjernMedarbejderFraBarnMutation,
  FjernMedarbejderFraBarnMutationVariables,
} from '@/generated/graphql';
import { apolloClient } from '@/graphql/apolloClient';

import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { fjernMedarbejderFraBarn } from '@/graphql/documents/mutations/barn/fjernMedarbejderFraBarn.graphql';

// GET
export const useFjernMedarbejdsFraBarnMutation = () => {
  // composables, providers and refs
  provideApolloClient(apolloClient);

  // mutation composable
  const { mutate, loading } = useMutation<
    FjernMedarbejderFraBarnMutation,
    FjernMedarbejderFraBarnMutationVariables
  >(fjernMedarbejderFraBarn);

  // promise to return in composable
  const submitFjernMedarbejderFraBarn = async (
    tilbudsMedarbejderBarnIds: string[],
    queries: string[],
  ): Promise<boolean> => {
    try {
      await mutate({ ids: tilbudsMedarbejderBarnIds }, { refetchQueries: queries });
    } catch (error) {
      return false;
    }

    return true;
  };

  // returns from composable
  return { submitFjernMedarbejderFraBarn, loading };
};
