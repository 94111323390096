<template>
  <div
    :data-test="dataTest || 'ist-layout-card'"
    class="ist-card"
    @click="cardClicked"
    :class="{
      shimmer: loading,
      clickable: navigateTo,
      dense: dense,
      card_box_shadow: shadow && !flat,
      flat: flat,
    }"
  >
    <ul v-if="false" class="ist-card__tabs">
      <li>
        <a href="#">&nbsp;&nbsp;</a>
      </li>
    </ul>
    <div v-if="!props.loading" class="ist-card__body" :style="{ minHeight: minh, height: '100%' }">
      <div class="ist-card__toolbar" v-if="hasToolbarSlot">
        <q-toolbar class="text-primary ist-actionbar">
          <slot name="toolbar">
            <div class="ist-card__toolbar-left">
              <slot name="toolbarleft"></slot>
            </div>
            <div class="ist-card__toolbar-right">
              <slot name="toolbarright" class="ist-card__toolbar-right"></slot>
            </div>
          </slot>
        </q-toolbar>

        <IstRoundButton
          v-if="showActionBtn"
          @click="actionBtnClicked"
          :label="actionBtnLabel"
          icon-name="add"
          class="action-btn"
          show-text-on-load
        />
        <IstHelpbutton class="action-btn" v-else-if="showHelpButton" :helpId="helpId" />
      </div>
      <IstIcon class="iconstyle" :name="iconName" v-if="iconName" />

      <div class="ist-card__body__section">
        <slot></slot>
      </div>
      <div v-if="showSecondarySectionHeader" class="ist-card__body__secondary-toolbar">
        <slot name="body-secondary-toolbar"></slot>
      </div>
      <div v-if="showSecondarySection" class="ist-card__body__section">
        <slot name="body-secondary-section"></slot>
      </div>

      <div class="ist-card__splitter ist-card__move-to-bottom" v-if="hasTags"></div>
      <div class="ist-card__body__section" v-if="hasTags">
        <div class="ist-arrow-right_start">
          <div
            class="ist-arrow-right"
            v-for="tag in tags"
            :key="tag.name"
            :style="setArrowColor(tag.color)"
            @click="tagClicked(tag.name)"
          >
            {{ tag.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CardTags } from './IstLayoutCard';
import { useSlots, computed } from 'vue';
import IstRoundButton from './common/IstRoundButton.vue';
import IstHelpbutton from './IstHelpbutton.vue';
import { IstIcon } from '@ist/brdk_uigruppe';
const slots = useSlots();

interface Props {
  iconName?: string;
  minh?: string;
  arrowColor?: string;
  tags?: CardTags[];
  showActionBtn?: boolean;
  actionBtnLabel?: string;
  loading?: boolean;
  showHelpButton?: boolean;
  helpId?: string;
  showSpinner?: boolean;
  miniCard?: boolean;
  dataTest?: string;
  navigateTo?: string;
  shadow?: boolean;
  dense?: boolean;
  flat?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  minh: '180px',
  arrowColor: '#f00000',
  shadow: false,
  tags: () => [],
  flat: false,
});

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'tagClick', value: string): void;
  (e: 'actionBtnClicked'): void;
}>();

const tagClicked = (value: string): void => {
  emit('tagClick', value);
};

const cardClicked = () => {
  emit('click');
};

const actionBtnClicked = () => {
  emit('actionBtnClicked');
};

const showSecondarySection = computed(() => {
  return slots['body-secondary-section'];
});
const showSecondarySectionHeader = computed(() => {
  return slots['body-secondary-toolbar'];
});

const hasTags = computed((): boolean => {
  return props.tags?.length > 0;
});

const setArrowColor = (arraycolor?: string) => {
  return {
    backgroundColor: arraycolor ?? props.arrowColor,
    borderColor: arraycolor ?? props.arrowColor,
  };
};

const hasToolbarSlot = computed(() => {
  return slots['toolbar'] || slots['toolbarleft'] || slots['toolbarright'];
});
</script>

<style lang="scss">
.ist-card {
  &__tabs {
    display: none;
    overflow: hidden;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      float: left;
      margin: 0 0.5em 0 0;
    }

    a {
      position: relative;
      background: #f0ad4e;
      padding: 0.7em 3.5em;
      float: left;
      text-decoration: none;
      color: #444;
      margin-left: 30px;
    }

    a:hover,
    a:hover::after,
    a:focus,
    a:focus::after {
      background: #fff;
    }

    a:focus {
      outline: 0;
    }

    a::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: -0.5em;
      bottom: 0;
      width: 1em;
      background: #f0ad4e;
      transform: skew(10deg);
      border-radius: 0 5px 0 0;
    }

    a::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: -0.5em;
      bottom: 0;
      width: 1em;
      background: #f0ad4e;
      -webkit-transform: skew(10deg);
      transform: skew(-12deg);
      border-radius: 5px 0px 0 0;
    }
  }

  &__splitter {
    border-top: 1px solid #ddd;
    margin: 0 16px;
  }

  &__move-to-bottom {
    margin-top: auto;
  }

  &__toolbar {
    border-bottom: 1px solid var(--ist-color-mono-light);
    position: relative;

    & .q-field--filled {
      & .q-field__control {
        background-color: #fafafa;
        border: none;
        border-radius: 0;
      }
    }
  }

  &__body {
    background: #ffffff;
    border-radius: 5px;
    vertical-align: top;
    position: relative;
    display: flex;
    flex-direction: column;

    & .footer {
      border-top: 1px solid #ddd;
      display: inline-block;
      position: relative;
      width: 100%;
      height: 30px;
    }

    & > div:first-child,
    & > img:first-child {
      border-top: 0;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
    }

    & > div:last-child,
    & > img:last-child {
      border-bottom: 0;
      border-bottom-left-radius: inherit;
      border-bottom-right-radius: inherit;
    }

    & > div:not(:first-child),
    & > img:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    & > div:not(:last-child),
    & > img:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & > div {
      border-left: 0;
      border-right: 0;
      box-shadow: none;
    }

    &__secondary-toolbar {
      border-top: 1px solid $ist-color-black;
      border-bottom: 1px solid $ist-color-black;
      padding-left: 16px;
    }

    &__section {
      position: relative;
      flex: 1;
      padding: 16px;
      overflow: visible;
      display: flex;
      flex-direction: column;

      & > .q-table__card {
        border: 0 !important;
        border-radius: 0 !important;
        margin: 0 -16px -10px -16px;
      }
    }
  }
}

.flat .ist-card__body {
  border: none;
  box-shadow: none;
}

.flat .ist-card__toolbar {
  border: none;
  box-shadow: none;
}

.dense .ist-card__body__section {
  padding: 8px;
}

.ist-card__toolbar-left {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
}

.ist-card__toolbar-right {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
}

.action-btn {
  position: absolute;
  z-index: 100;
  right: -18px;
  bottom: -18px;
}

.istcard {
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.12));
}

.ist-arrow-right_start {
  margin-left: -25px;
  margin-top: -8px;
}

.ist-arrow-right {
  padding: 6px 18px 06px 32px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border: 1px solid transparent;
  color: #000;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  border-radius: 5px 2px 2px 5px !important;
  margin-top: 8px;
  float: left;
  position: relative;
  margin-right: 15px;
}

.ist-arrow-right::after {
  content: '';
  top: 4px;
  right: -11px;
  position: absolute;
  width: 24px;
  height: 24px;
  background: inherit;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-radius: 2px 5px 2px 20px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.iconstyle {
  text-align: center;
  font-size: 3em;
  width: 100%;
  padding-top: 8px;
}

.clickable .ist-card__body {
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.22));

  cursor: pointer;
  transition: all ease-in-out 200ms;
}

.clickable:hover .ist-card__body {
  background-color: rgba(0, 0, 0, 0.01);
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0));
}

.ist-actionbar {
  flex-wrap: wrap;
}

.card_box_shadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.shimmer {
  background: #f6f7f8;
  border: solid 1px lightgray;
  border-radius: 8px;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
  min-height: 200px;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
