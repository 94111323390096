import { useAktiverTilbudsmedarbejderMutation } from './mutations/aktiverTilbudsmedarbejderAdmin';
import { useEditBarnTilknytningerMutation } from './mutations/editBarnTilknytninger';
import { useOpretBarnMutation } from './mutations/opretBarn';
import { useAddNewTilbud } from './mutations/opretTilbud';

export default {
  useAktiverTilbudsmedarbejderMutation,
  useEditBarnTilknytningerMutation,
  useOpretBarnMutation,
  useAddNewTilbud,
};
