import { IModule } from '../security/models/module-model';
import i18n from '@/i18n';
import { UserType } from '@/generated/graphqlUser';

export default <IModule>{
  routes: [
    {
      path: '/medarbejder',
      name: 'employee',
      meta: { requiresAuth: true, title: 'Medarbejder' },
      component: () =>
        import(/* webpackChunkName: "medarbejder" */ './medarbejder/tilbudMedarbejderOversigt.vue'),
    },
    {
      path: '/godkendelse',
      name: 'godkendelse',
      meta: { requiresAuth: true, title: 'Validering' },
      component: () =>
        import(/* webpackChunkName: "godkendelse" */ './godkendelse/GodkendelsesView.vue'),
    },
    {
      path: '/boern/id/:child_id?',
      name: 'child',
      meta: {
        requiresAuth: true,
        title: 'Barn stamkort',
        authorize: [UserType.Forvalter],
      },

      component: () => import(/* webpackChunkName: "boern" */ './barnetsKort/BarnetsKort.vue'),
    },
    {
      path: '/boern',
      name: 'children',
      meta: {
        requiresAuth: true,
        title: 'Børn Oversigt',
        authorize: [UserType.Forvalter],
      },

      component: () => import(/* webpackChunkName: "boern" */ './boern/BoernOversigt.vue'),
    },
    {
      path: '/tilbud/id/:tilbud_id',
      name: 'tilbudpage',
      meta: { requiresAuth: true, title: 'Tilbud stamkort', authorize: [UserType.Forvalter] },
      component: () =>
        import(/* webpackChunkName: "tilbud" */ './tilbud/tilbudside/tilbudSide.vue'),
    },

    {
      path: '/tilbud',
      name: 'tilbud',
      meta: {
        requiresAuth: true,
        title: i18n.global.t('menu.Tilbud'),
        authorize: [UserType.Forvalter],
      },
      component: () => import(/* webpackChunkName: "tilbud" */ './tilbud/TilbudOversigt.vue'),
    },
  ],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  menu: [
    {
      title: 'Socialtilbud',
      iconName: 'mdi-home-account',
      priority: 2,
      name: 'socialtilbud',
      userRightNames: ['menupunktbarn'],
      hideFrom: UserType.TilbudsmedarbejderAdmin,
      menuEntries: [
        {
          title: i18n.global.t('menu.Children'),
          entryPointUrl: '/boern',
          iconName: 'mdi-human-child',
          priority: 2,
          name: 'children',
          userRightNames: ['menupunktbarn'],
          offset: true,
        },
        {
          title: i18n.global.t('menu.Tilbud'),
          entryPointUrl: '/tilbud',
          iconName: 'mdi-home',
          priority: 100,
          name: 'tilbud',
          userRightNames: ['menupunktsocialtilbud'],
          offset: true,
        },
      ],
    },
    {
      title: 'Børn',
      entryPointUrl: '/boern',
      iconName: 'mdi-human-child',
      priority: 2,
      name: 'children',
      userRightNames: ['menupunktbarn'],
      hideFrom: UserType.Forvalter,
    },
    {
      title: 'Medarbejder',
      entryPointUrl: '/medarbejder',
      iconName: 'mdi-home-account',
      priority: 4,
      name: 'employee',
      hideFrom: UserType.Forvalter,
      userRightNames: ['menupunktmedarbejder'],
    },
    {
      title: 'Validering',
      entryPointUrl: '/godkendelse',
      iconName: 'mdi-check-circle',
      priority: 6,
      name: 'godkendelse',
      userRightNames: ['menupunktgodkendelse'],
    },
  ],
};
