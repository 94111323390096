<template>
  <Teleport to="body">
    <Transition name="dialog" mode="in-out">
      <div v-show="dialogState.active" class="dialog-mask">
        <div class="dialog-outside-clicker" @click="dialog.cancel()"></div>

        <div class="dialog-wrapper">
          <div class="dialog-container">
            <div class="dialog-header">
              <h3>{{ dialogState.title }}</h3>
            </div>
            <div class="dialog-content">
              {{ dialogState.message }}
              <IstFormInput
                data-test="dialog-input"
                ref="input"
                outlined
                v-if="dialogState.hasInput"
                v-model="dialogState.inputModel"
              ></IstFormInput>
            </div>
            <div class="dialog-button-row">
              <IstButton
                data-test="dialog-btn-cancel"
                class="dialog-button"
                rounded
                size="sm"
                v-if="dialogState.cancelText"
                @click="dialog.cancel()"
                >{{ dialogState.cancelText }}</IstButton
              >
              <IstButton
                :disable="dialogState.hasInput && dialogState.inputModel.length <= 3"
                class="dialog-button"
                data-test="dialog-btn-ok"
                rounded
                size="sm"

                color="primary"
                @click="dialog.confirm()"
                >{{ dialogState.okText }}</IstButton
              >
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import IstFormInput from '@/components/forms/IstFormInput.vue';
import IstButton from '@/components/common/IstButton.vue'
import { useIstDialog } from './IstDialog';

const { dialog, dialogState } = useIstDialog();

const input = ref<InstanceType<typeof IstFormInput> | null>(null);

// since component is mounted, we need to make sure it is actually loaded
watch(
  () => dialogState.active,
  (newVal) => {
    if (newVal) {
      setTimeout(() => {
        input.value?.$el?.focus();
      }, 100);
    }
  },
);
</script>

<style scoped lang="scss">
.dialog-enter-from {
  opacity: 0;
}

.dialog-leave-to {
  opacity: 0;
}

.dialog-enter-from .dialog-container,
.dialog-leave-to .dialog-container {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}

.dialog-outside-clicker {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: table;
}
.dialog-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.dialog-container {
  width: 550px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  z-index: 5001;
}
.dialog-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  z-index: 5000;
}

.dialog-header {
  border-bottom: 1px solid $ist-color-grey;
  padding: 5px 15px;
}

.dialog-content {
  padding: 10px 20px;
  background-color: $ist-color-grey-light;
  min-height: 100px;
  border-bottom: 1px solid $ist-color-grey;
  white-space: pre-wrap;
}

.dialog-button-row {
  height: auto;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.dialog-button {
  width: auto;
}
</style>
