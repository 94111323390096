<template>
  <div @click="handleClick" @mouseover="hover = true" @mouseleave="hover = false" class="container">
    <button :class="buttonStyle">
      <span v-if="label" :class="{ hover: hover }">{{ label }}</span>
      <IstIcon :class="buttonStyle" size="24px" :name="iconName" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const hover = ref<boolean>(false);

interface Props {
  label?: string;
  iconName: string;
  showTextOnLoad?: boolean;
  buttonStyle?: 'danger' | 'default';
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const handleClick = () => {
  emit('click');
};

onMounted(() => {
  if (props.showTextOnLoad) {
    hover.value = true;
    setTimeout(() => {
      hover.value = false;
    }, 1000);
  }
});
</script>

<style scoped lang="scss">
.container {
  float: right;
  text-align: right;
  font-family: sans-serif;
  cursor: pointer;
}
button {
  background: $ist-button-primary-bg;
  padding: 6px;
  border-radius: 50px;
  align-content: center;
  border: none;
  cursor: pointer;
}
button span {
  display: inline-block;
  visibility: none;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0px;
  font-size: 12px;
  vertical-align: middle;
  color: white;
  margin: 0 auto;
  opacity: 0;
  text-align: center;
  transition: all 1s;
}
i {
  z-index: 999;
  background: $ist-button-primary-bg;
  color: white;
}
.hover {
  margin-right: 0px;
  opacity: 1;
  width: auto;
  max-width: 500px;
  padding-right: 10px;
  padding-left: 12px;
}

.danger {
  background: $ist-color-red-xdark;
}
</style>
