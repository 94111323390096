export interface ScreenSizes {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const screenSizes: ScreenSizes = {
  xs: 0,
  sm: 600,
  md: 1024,
  lg: 1440,
  xl: 1920,
};

const spacers: ScreenSizes = {
  xs: 4,
  sm: 4,
  md: 8,
  lg: 10,
  xl: 12,
};

export const BUTTON_SIZES = {
  sm: 20,
  md: 30,
  lg: 40,
  xl: 50,
};
export const sizes = Object.values(screenSizes);
export const sizeNames = Object.keys(screenSizes);
export const spacings = Object.values(spacers);
