import {
  OpretTilbudInput,
  OpretTilbudMutation,
  OpretTilbudMutationVariables,
} from '@/generated/graphql';
import { apolloClient } from '@/graphql/apolloClient';
import { opretTilbud } from '@/graphql/documents/mutations/tilbud/tilbudMutations.graphql';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';

export const useAddNewTilbud = () => {
  provideApolloClient(apolloClient);

  const { mutate, loading } = useMutation<OpretTilbudMutation, OpretTilbudMutationVariables>(
    opretTilbud,
    {
      update: (cache, { data }) => {
        cache.modify({
          fields: {
            tilbud(existingTilbud = []) {
              const newTilbudRef = cache.writeFragment({
                data: data?.opretTilbud,
                fragment: gql`
                  fragment newTilbud on Tilbud {
                    id
                  }
                `,
              });
              return [...existingTilbud, newTilbudRef];
            },
          },
        });
      },
    },
  );

  const submitTilbud = async (tilbudsInfo: OpretTilbudInput): Promise<string | undefined> => {
    try {
      const result = await mutate({
        tilbud: {
          vejnavn: tilbudsInfo.vejnavn || '',
          cvr: tilbudsInfo.cvr,
          navn: tilbudsInfo.navn || '',
          kommune: tilbudsInfo.kommune || '',
          husnummer: tilbudsInfo.husnummer || '',
          postnummer: tilbudsInfo.postnummer || '',
          bynavn: tilbudsInfo.bynavn || '',
          tilbudUUID: tilbudsInfo.tilbudUUID,
        },
      });
      return result?.data?.opretTilbud.id;
    } catch (error) {
      return undefined;
    }
  };

  return { submitTilbud, loading };
};
