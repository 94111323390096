import { AccessLevel } from '@/generated/graphql';
import { apolloClient } from '@/graphql/apolloClient';
import { provideApolloClient } from '@vue/apollo-composable';

import { useFjernMedarbejdsFraBarnMutation } from '../fjernMedarbejderFraBarn';
import { useTilknytMedarbejderTilBarn } from './tilknytMedarbejderTilBarn';
import { useUpdateMedarbejderBarnMutation } from './updateMedarbejderBarn';

export const useEditBarnTilknytningerMutation = () => {
  // composables, providers and refs
  provideApolloClient(apolloClient);
  const loading = ref<boolean>(false);

  // mutation composables
  const { submitFjernMedarbejderFraBarn } = useFjernMedarbejdsFraBarnMutation();
  const { submitTilknytInfo } = useTilknytMedarbejderTilBarn();
  const { submitUpdateMedarbejderBarnMutations } = useUpdateMedarbejderBarnMutation();

  interface NewTilknytningData {
    barnId: string;
    medarbejderId: string;
    accessLevel: AccessLevel;
  }
  // promise to return in composable
  const submitEditBarnTilknytninger = async (
    payload: {
      newMedarbejderTilknytning: NewTilknytningData[];
      medarbejderToRemoveIds: string[];
      medarbejdereToUdateAccessLevel: NewTilknytningData[];
    },
    queries: string[],
  ): Promise<boolean> => {
    loading.value = true;
    //
    try {
      if (payload.medarbejderToRemoveIds.length > 0) {
        await submitFjernMedarbejderFraBarn(payload.medarbejderToRemoveIds, queries);
      }
      if (payload.newMedarbejderTilknytning.length > 0) {
        await submitTilknytInfo(payload.newMedarbejderTilknytning, []);
        await submitUpdateMedarbejderBarnMutations(payload.newMedarbejderTilknytning, queries);
      }
      if (payload.medarbejdereToUdateAccessLevel.length > 0) {
        await submitUpdateMedarbejderBarnMutations(payload.medarbejdereToUdateAccessLevel, []);
      }
    } catch (error) {
      loading.value = false;
      return false;
    }

    loading.value = false;
    return true;
  };

  // returns from composable
  return { submitEditBarnTilknytninger, loading };
};
