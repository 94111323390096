import * as Sentry from '@sentry/browser';
import { Institution } from './models/institution';
import { defineStore } from 'pinia';
import {
  BrugerAdgangStatusType,
  CustomError,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  UserType,
  ViewAccessRight,
} from '@/generated/graphqlUser';
import { apolloClient } from '@/graphql/apolloClient';

import { GetCurrentUser } from '@/graphql/documents/loginqueries/currentUser.graphql';
import { useRouter } from 'vue-router';
import { NetworkStatus } from '@apollo/client/core';
import { provideApolloClient } from '@vue/apollo-composable';
import { UserRights } from './models/user-model';

export type CurrentUser = GetCurrentUserQuery['currentUser'];
export interface User extends CurrentUser {
  userRights: Map<string, UserRights>;
  activeEntityId: string;
  userCustomErrors: CustomError[];
  institutioner: Institution[];
  userAccessStatus: BrugerAdgangStatusType;
}

export const useSecurityStore = defineStore('security', () => {
  // state
  const authenticated = ref(false);
  const logoutUrl = ref('');
  const user = ref<User>();
  const userLoading = ref(false);
  const versionId = ref('');

  // GETTERS
  const getVersionId = computed(() => versionId.value);
  const getAuthenticated = computed(() => !!sessionStorage.getItem('token'));
  const getCustomUserErrors = computed(() => user.value?.userCustomErrors);
  const hasCustomUserErrors = computed(
    () => getCustomUserErrors.value && getCustomUserErrors.value.length > 0,
  );
  const getAuthenticationToken = computed(() => {
    return sessionStorage.getItem('token') || '';
  });
  const getUser = computed(() => user);
  const getCurrentUserAccessStatus = computed(() => {
    return (
      user.value?.institutioner.find((inst) => {
        return inst.id === user.value?.activeEntityId;
      })?.bruger_adgang_status ?? BrugerAdgangStatusType.CanNotAccess
    );
  });
  const getInstitutions = computed(() => user.value?.institutioner);
  const getSelectedInstitution = computed(() => {
    return user.value?.activeEntityId;
  });
  const getActiveEntityId = computed(() => {
    return user.value?.activeEntityId ?? '';
  });
  const getIsUserLoaded = computed(() => {
    return userLoading.value;
  });

  // ACTIONS
  const setVersionid = (verId: string): void => {
    if (verId.length > 1 && verId !== versionId.value) {
      versionId.value = verId;
    }
  };

  const getActiveInstitution = computed((): Institution | undefined => {
    return user.value?.institutioner.find((i) => i.id === user.value?.activeEntityId);
  });

  const setUser = (innerUser: User | undefined): void => {
    if (innerUser) {
      user.value = innerUser;
    }
    if (user.value) {
      user.value.userAccessStatus = getCurrentUserAccessStatus.value;
      Sentry.setUser({ username: user.value.name ?? '' });
      Sentry.setTag('activeEntityId', user.value.activeEntityId.toString());
    }
  };
  const can = computed(() => {
    return (action: ViewAccessRight, subject: string, data: any = undefined): boolean => {
      const key = action.toLowerCase() + subject.toLowerCase();
      const right = user.value?.userRights.get(key);
      if (right) {
        return right?.dataValidator && data ? right?.dataValidator(data) : true;
      }
      return false;
    };
  });
  const canSet = (
    action: string,
    subject: string,
    value: boolean,
    dataValidator: any = undefined,
  ): boolean => {
    const key = action.toLowerCase() + subject.toLowerCase();
    if (!value) {
      user.value?.userRights.delete(key);
    } else {
      user.value?.userRights.set(key, {
        action: action,
        subject: subject,
        dataValidator: dataValidator,
      });
    }
    return false;
  };

  const setLogoutUrl = (url: string): void => {
    logoutUrl.value = url;
  };

  const setActiveEntityId = (activeEntityId: string): void => {
    if (!user.value) {
      return;
    }
    user.value.activeEntityId = activeEntityId;
    localStorage.setItem('activeEntityId', activeEntityId);
    apolloClient.resetStore();
  };
  async function fetchUser() {
    provideApolloClient(apolloClient);

    const router = useRouter();

    const { onResult, networkStatus } = useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
      GetCurrentUser,
      {},
      {
        context: { clientName: 'userapi' },
      },
    );

    // If query has a networkstatus, it means there is a cached object - so we need to refetch the query to update the cache.

    // Result hook.
    onResult(({ data }) => {
      if (networkStatus.value === NetworkStatus.ready && data.currentUser.firstForvalterLogin) {
        router.replace({ name: 'firstlogin' });
      }
      let currentUser: User = createEmptyUser();

      if (data?.currentUser) {
        if (data.currentUser.brugerAdgange) {
          currentUser = getUserInstitutions(data, currentUser);
        }
        if (data.currentUser.accessRights) {
          currentUser = setAccessRights(data, currentUser);
        }
        const activitEntityIdFromLocalStorage = localStorage.getItem('activeEntityId');
        const userHasEntityId = currentUser.institutioner.some(
          (i) => i.id === activitEntityIdFromLocalStorage,
        );
        if (activitEntityIdFromLocalStorage && userHasEntityId) {
          currentUser.activeEntityId = activitEntityIdFromLocalStorage;
        } else {
          currentUser.activeEntityId = getActiveEntityIdFromResult(data.currentUser);
        }
        currentUser = {
          ...currentUser,
          ...data.currentUser,
        };
        // use result to set user
        user.value = currentUser;
        userLoading.value = false;
      }
    });
  }
  const shortname = computed((): string => {
    const outString =
      user.value?.name?.replaceAll(/[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/g, '') ?? '';
    const namesplit = outString.split(' ');

    // Extract the first two characters of a string
    if (namesplit.length == 1) {
      return namesplit[0].trim().substring(0, 2).toUpperCase();
    } else {
      return namesplit
        .map((localName) => localName[0])
        .join('')
        .toUpperCase();
    }
  });
  const currentInstitution = (): Institution | undefined => {
    let curInstitution: Institution | undefined;
    if (!user.value) {
      return undefined;
    }
    curInstitution = user.value.institutioner.find((i) => i.id === user.value?.activeEntityId);

    if (!curInstitution) {
      curInstitution = user.value.institutioner.find(() => true);
    }
    return curInstitution;
  };
  const isAuthenticated = async (): Promise<boolean> => {
    return Promise.resolve(!!sessionStorage.getItem('token'));
  };

  return {
    authenticated,
    logoutUrl,
    user,
    userLoading,
    versionId,
    getVersionId,
    getAuthenticated,
    getCustomUserErrors,
    hasCustomUserErrors,
    getAuthenticationToken,
    getUser,
    getCurrentUserAccessStatus,
    getInstitutions,
    getSelectedInstitution,
    getActiveEntityId,
    getIsUserLoaded,
    setVersionid,
    setUser,
    can,
    canSet,
    setLogoutUrl,
    setActiveEntityId,
    shortname,
    getActiveInstitution,
    fetchUser,
    currentInstitution,
    isAuthenticated,
  };
});

const getActiveEntityIdFromResult = (result: GetCurrentUserQuery['currentUser']): string => {
  if (result?.userType == UserType.Ist) {
    return '00000000-0000-0000-0000-000000000000'; // Hardcoded value does not matter
  }

  const forvalterOrganisationClaim: string | undefined = result?.brugerAdgange?.find(
    (brugeradgang) => {
      return brugeradgang?.organisationId !== null;
    },
  )?.organisationId;
  const forvalterKommuneClaim: string | undefined = result?.brugerAdgange?.find((brugeradgang) => {
    return brugeradgang?.kommuneId !== null;
  })?.kommuneId;
  const tilbudsAdminClaim: string | undefined = result?.brugerAdgange?.find((brugeradgang) => {
    return brugeradgang?.tilbudId !== null;
  })?.tilbudId;
  return forvalterOrganisationClaim ?? forvalterKommuneClaim ?? tilbudsAdminClaim ?? '';
};

function setAccessRights(data: GetCurrentUserQuery, currentUser: User): User {
  const tempUser = { ...currentUser };
  data.currentUser.accessRights.forEach((accessRight) => {
    if (accessRight) {
      accessRight.rights.forEach((right) => {
        tempUser.userRights.set(right.toLowerCase() + accessRight.entity.toLowerCase(), {
          action: right.toLowerCase(),
          subject: accessRight.entity.toLowerCase(),
        });
      });
    }
  });
  return tempUser;
}

function createEmptyUser(): User {
  return {
    id: '',
    name: '',
    email: '',
    userType: UserType.Ukendt,
    userRights: new Map<string, UserRights>(),
    activeEntityId: '',
    userCustomErrors: [],
    institutioner: [],
    userAccessStatus: BrugerAdgangStatusType.CanNotAccess,
    accessRights: [],
    brugerAdgange: [],
    customErrors: [],
    firstForvalterLogin: false,
    hasActiveEntityId: false,
    userSessionHash: 0,
    cvr: undefined,
  };
}

function getUserInstitutions(data: GetCurrentUserQuery, currentUser: User): User {
  const tempUser = { ...currentUser };
  const numAccesses: number = data.currentUser.brugerAdgange.length;
  for (let index = 0; index < numAccesses; index++) {
    const adgang = data.currentUser.brugerAdgange[index];
    const institution = {} as Institution;
    institution.cvr_number = adgang?.cvrNummer;
    institution.name = adgang?.organisationNavn || '';
    institution.bruger_adgang_status =
      adgang?.brugerAdgangStatus || BrugerAdgangStatusType.CanNotAccess;
    institution.id = adgang?.organisationId || adgang?.tilbudId;
    tempUser.institutioner.push(institution);
  }
  return tempUser;
}
