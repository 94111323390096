import { createI18n, I18nOptions } from 'vue-i18n';
import da from './locales/da.json';
/* https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
 *
 */

// we build a main file with the default locale, the other languages are loaded later
const defaultLocale = import.meta.env.VUE_APP_I18N_LOCALE || 'da';

// load our language chunk and set our language after request
// will refresh the views with the new language immediately

export const SUPPORT_LOCALES = ['da'];

const numberFormats = {
  da: {
    currency: {
      style: 'currency',
      currency: 'DKK',
    },
    percent: {
      style: 'percent',
    },
    decimal: {
      style: 'decimal',
    },
  },
};

const defaultConfig: I18nOptions = {
  locale: 'da',
  legacy: false,
  fallbackLocale: defaultLocale,
  messages: { da: da },
  globalInjection: true,
  numberFormats: numberFormats,
  missingWarn: false,
  silentTranslationWarn: process.env.NODE_ENV === 'test',
};

export default createI18n(defaultConfig);

export function setI18nLanguage(i18n, locale) {
  if (i18n.mode === 'legacy') {
    i18n.global.locale = locale;
  } else {
    i18n.global.locale.value = locale;
  }
}
