import { defineStore } from 'pinia';

export type MessageTypes =
  | 'NonBreakingDataError'
  | 'BreakingDataError'
  | 'InformationDataDone'
  | 'InformationDataSubmitted';
interface Message {
  type: MessageTypes;
  message: string;
}

interface MessageQueueState {
  currentMessage: Message | undefined;
}

export const useMessageQueueStore = defineStore('messageQueue', {
  state: (): MessageQueueState => ({
    currentMessage: undefined,
  }),
  actions: {
    pushMessage(messageEvent: MessageTypes, message: string) {
      this.currentMessage = { type: messageEvent, message };
    },
  },
});
