<template>
  <div class="">
    <IstButton size="md" round color="primary" iconName="mdi-help" @click="handleHelpClicked" />
  </div>
</template>

<script setup lang="ts">
import { modalDialog } from '@/components/modal';
import HelpDialog from '../help/HelpDialog.vue';
import { IstButton } from './common';
export interface Props {
  helpId: string;
}
const props = withDefaults(defineProps<Props>(), { helpId: '' });

const handleHelpClicked = () => {
  modalDialog.open(HelpDialog, { helpId: props.helpId });
};
</script>
