import { IModule } from '../security/models/module-model';

export default <IModule>{
  routes: [
    {
      path: '/beskeder',
      name: 'beskeder',
      meta: { requiresAuth: true, title: 'Beskeder' },
      component: () => import('./BeskedIndbakke.vue'),
    },
  ],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  menu: [
    {
      title: 'Beskeder',
      name: 'beskeder',
      entryPointUrl: '/beskeder',
      iconName: 'mdi-email',
      priority: 101,
      userRightNames: ['menupunktforside'],
    },
  ],
};
