<template>
  <q-input
    @clear="emit('clear')"
    v-bind="$attrs"
    :modelValue="localvalue"
    @update:modelValue="onChange"
    :filled="filled"
    :outlined="outlined"
    :standout="standout"
    :borderless="borderless"
  >
    <template v-for="(_, slot) in $slots" #[slot]="scope">
      <slot :name="slot" v-bind="scope || {}" />
    </template>
  </q-input>
</template>

<script setup lang="ts">
import { QInputProps } from 'quasar';
import { onMounted, ref, watch } from 'vue';

export type IstInputFormModelValue = QInputProps['modelValue'];

interface IstFormInputProps {
  validation?: any;
  modelValue?: string | number | null;
  modelModifiers?: { default: () => Record<string, unknown> };
  placeHolder?: string;
  filled?: boolean;
  outlined?: boolean;
  standout?: boolean;
  borderless?: boolean;
}

const props = defineProps<IstFormInputProps>();
const emit = defineEmits<{
  (e: 'clear'): void;
  (e: 'change', value: IstInputFormModelValue): void;
  (e: 'input', value: IstInputFormModelValue): void;
  (e: 'update:modelValue', value: IstInputFormModelValue): void;
}>();

const inputType = ref(typeof props.modelValue);

// store initial model type, to prevent Q-Input from converting number to string.
onMounted(() => {
  inputType.value = typeof props.modelValue;
});

// local model value
const localvalue = ref<IstInputFormModelValue>(props.modelValue);

// Make sure that fields are in sync
watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue !== localvalue.value) {
      localvalue.value = newValue;
    }
  },
);

const onChange = (value: IstInputFormModelValue) => {
  // if original input type is number, and q-input convert emit to string, convert back to number
  localvalue.value =
    value && typeof value === 'string' && inputType.value === 'number' ? Number(value) : value;

  emit('change', localvalue.value);
  emit('input', localvalue.value);
  emit('update:modelValue', localvalue.value);
};
</script>
