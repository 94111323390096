import {
  TilknytBarnTilMedarbejderMutation,
  TilknytBarnTilMedarbejderMutationVariables,
} from '@/generated/graphql';
import { apolloClient } from '@/graphql/apolloClient';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { tilknytBarnTilMedarbejder } from '@/graphql/documents/mutations/barn/tilknytBarnTilMedarbejder.graphql';

export const useTilknytMedarbejderTilBarn = () => {
  provideApolloClient(apolloClient);

  const { mutate, loading } = useMutation<
    TilknytBarnTilMedarbejderMutation,
    TilknytBarnTilMedarbejderMutationVariables
  >(tilknytBarnTilMedarbejder);

  interface TilknytningsData {
    barnId: string;
    medarbejderId: string;
    hasFullAccessLevel?: boolean;
  }

  const submitTilknytInfo = async (
    tilknytningsInput: TilknytningsData[],
    queries: string[],
  ): Promise<boolean> => {
    for (const input of tilknytningsInput) {
      try {
        await mutate(input, { refetchQueries: queries });
      } catch (error) {
        return false;
      }
    }
    return true;
  };

  return { submitTilknytInfo, loading };
};
