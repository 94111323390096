import IstModal from './IstModal.vue';
import IstLayoutCard from './IstLayoutCard.vue';
import IstAvatar from './IstAvatar.vue';
import IstHelpbutton from './IstHelpbutton.vue';
// FORM
import IstFormInput from './forms/IstFormInput.vue';
import IstFormSelect from './forms/IstFormSelect.vue';
import IstCheckbox from './common/IstCheckBox.vue';
// COMMON
import IstButton from './common/IstButton.vue';
import IstField from './common/IstField.vue';

export default {
  install(Vue) {
    Vue.component('ISTModal', IstModal);
    Vue.component('ISTLayoutCard', IstLayoutCard);
    Vue.component('ISTFormSelect', IstFormSelect);
    Vue.component('ISTFormInput', IstFormInput);
    Vue.component('ISTAvatar', IstAvatar);
    Vue.component('ISTHelpbutton', IstHelpbutton);
    Vue.component('ISTCheckBox', IstCheckbox);
    Vue.component('ISTButton', IstButton);
    Vue.component('ISTField', IstField);
  },
};
