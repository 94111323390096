import { OpretBarnMutation, OpretBarnMutationVariables } from '@/generated/graphql';
import { apolloClient } from '@/graphql/apolloClient';
import { provideApolloClient, useMutation } from '@vue/apollo-composable';
import { opretBarn } from '@/graphql/documents/mutations/barn/opretBarn.graphql';
import gql from 'graphql-tag';
export interface OpretBarnInput {
  cpr: string;
  tilbudId: string;
  gyldigFra: Date;
  gyldigTil?: Date;
}
export const useOpretBarnMutation = () => {
  provideApolloClient(apolloClient);

  const {
    mutate: opretBarnMutate,

    loading,
  } = useMutation<OpretBarnMutation, OpretBarnMutationVariables>(opretBarn, {
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          boern(existingBoern = []) {
            const newBarnRef = cache.writeFragment({
              data: data?.tilknytNytBarnTilTilbud,
              fragment: gql`
                fragment NewBarn on Barn {
                  id
                }
              `,
            });
            return [...existingBoern, newBarnRef];
          },
        },
      });
    },
  });

  const submitOpretBarn = async (input: OpretBarnInput): Promise<string | undefined> => {
    try {
      const mutationResult = await opretBarnMutate(input, {
        // refetchQueries: queries
      });
      return mutationResult?.data?.tilknytNytBarnTilTilbud.id;
    } catch (error) {
      return undefined;
    }
  };

  return { submitOpretBarn, loading };
};
