const unaryTags = [
  'area',
  'base',
  'br',
  'col',
  'embed',
  'hr',
  'img',
  'input',
  'keygen',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr',
];

function renderStartTag(VNode) {
  if (typeof VNode.type === 'symbol') {
    return '';
  }
  let html = `<${VNode.type}`;

  if (VNode.props) {
    const attr = VNode.props;
    for (const name in attr) {
      if (attr[name] === '') {
        html += ` ${name}`;
      } else {
        html += ' ' + name.toString() + "='";
        const o = attr[name.toString()];
        if (o && o.constructor === Object) {
          for (const [key, value] of Object.entries(o)) {
            html += `${key}: ${value};`;
          }
        } else {
          html += attr[name.toString()];
        }
        html += "'";
      }
    }
  }

  return html + '>';
}

function isUnaryTag(VNode) {
  return unaryTags.indexOf(VNode.tag) > -1;
}

function getFullTag(VNode) {
  if (VNode.type.toString() == 'Symbol(Comment)') {
    return '';
  }
  if (VNode.type.toString() == '' || VNode.type.toString() == 'Symbol(Text)') {
    return VNode.children;
  }

  let html = renderStartTag(VNode);

  if (VNode.children) {
    html += getChildren(VNode);
  }
  if (!isUnaryTag(VNode) && typeof VNode.type !== 'symbol') {
    html += `</${VNode.type}>`;
  }
  return html;
}

function getChildren(VNode) {
  let html = '';
  if (Array.isArray(VNode.children)) {
    for (const i in VNode.children) {
      const child = VNode.children[i];
      html += getFullTag(child);
    }
  } else {
    html = VNode.children;
  }
  return html;
}
export default {
  created(vNode) {
    const VNodes = [vNode];
    let renderedHead = '';

    for (const i in VNodes) {
      const VNode = VNodes[i];
      renderedHead += getFullTag(VNode);
    }
    return renderedHead;
  },
  render(h) {
    return h(
      'div',
      {
        class: 'servueWrapper',
      },
      this['$slots'].content,
    );
  },
};
