<template>
  <QSelect
    data-test="q-select"
    v-model="selectedOptions"
    :stack-label="!dense"
    :use-input="hasInput"
    fill-input
    :multiple="multiple"
    :use-chips="multiple && useChips"
    ref="qSelect"
    :options="curOptions"
    :disable="disabled"
    :readonly="readonly || disabled"
    :label="label"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    style="padding-bottom: 0"
    :input-style="inputStyle"
    :dense="dense"
  >
    <template #option="scope">
      <div :data-test="`select-option-${scope.opt.value}`">
        <q-item-label header v-show="showGroupText(scope.opt.grouptext, scope.index)">{{
          scope.opt.grouptext
        }}</q-item-label>
        <q-item v-bind="scope.itemProps">
          <q-item-section avatar v-if="scope.opt.icon">
            <IstIcon :name="scope.opt.icon" />
          </q-item-section>
          <q-item-section :data-test="`select-item-${scope.opt.value}`">
            <q-item-label><div v-text="scope.opt.label"></div></q-item-label>
            <q-item-label caption>{{ scope.opt.description }}</q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </template>
  </QSelect>
</template>

<script setup lang="ts">
import { QSelect } from 'quasar';
import { onMounted, ref, computed, watch } from 'vue';
import { SelectionOption } from './types';

const qSelect = ref<QSelect>();

interface SelectionProps {
  modelValue: any;
  disabled?: boolean;
  label?: string;
  options: SelectionOption[];
  dense?: boolean;
  multiple?: boolean;
  inputStyle?: string;
  readonly?: boolean;
  hasInput?: boolean;
  useChips?: boolean;
}

const props = withDefaults(defineProps<SelectionProps>(), { options: () => [] });
const emit = defineEmits<{
  (e: 'input', value: any): void;
  (e: 'change', value: any): void;
  (e: 'update:modelValue', value: any): void;
}>();

const ldense = ref(false);
const curOptions = ref<Array<SelectionOption>>([]);
const localvalue: any = ref();

const updateValue = () => {
  emit('input', localvalue.value);
  emit('change', localvalue.value);
  emit('update:modelValue', localvalue.value);
};

const onOptionsChanged = (val: any) => {
  curOptions.value = val;
  let oldtxt = '';
  for (const element of curOptions.value) {
    const s = element.grouptext ? element.grouptext : '';
    if (s !== '' && oldtxt != s) {
      oldtxt = s;
    } else {
      element.grouptext = undefined;
    }
  }
};

onMounted(() => {
  if (props.modelValue) {
    localvalue.value = props.modelValue;
  }
  if (props.dense) {
    ldense.value = props.dense;
  }
  onOptionsChanged(props.options);
});

watch(
  () => props.modelValue,
  () => {
    localvalue.value = props.modelValue;
  },
);

const showGroupText = (groupText: string, index: number) => {
  if (groupText === undefined) {
    return false;
  }
  if (index === 0) {
    return groupText === '' ? false : true;
  }
  const previusOption = curOptions.value[index - 1];
  return previusOption.grouptext !== groupText && groupText !== '' ? true : false;
};

const selectedOptions = computed({
  get() {
    if (props.multiple) {
      if (localvalue.value) {
        return props.options.filter((value: SelectionOption) => {
          return localvalue.value.some((localVal: any) => objectComparer(localVal, value.value));
        });
      }
      return [];
    } else if (props.modelValue) {
      return props.options.find((value: SelectionOption) => {
        return localvalue.value === value.value;
      });
    } else {
      return '';
    }
  },
  set(selection: any) {
    if (props.multiple) {
      if (Array.isArray(selection)) {
        localvalue.value = selection.map((value: SelectionOption) => value.value);
      } else {
        localvalue.value = null;
      }
      (qSelect.value as QSelect).updateInputValue('', false);
    } else {
      localvalue.value = selection.value;
    }
    updateValue();
  },
});

const objectComparer = (obj1: any, obj2: any) => {
  if (obj1.equals && obj2.equals) {
    // both objects have a built in equals method, using it
    return obj1.equals(obj2) && obj2.equals(obj1);
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  for (const key of obj1Keys) {
    const obj1Val = obj1[key];
    const obj2Val = obj2[key];

    if (obj1Val === undefined) {
      if (obj2Val !== undefined) {
        return false;
      }
    }

    if (obj1Val !== obj2Val) {
      return false;
    }
  }
  return true;
};
</script>
<style scoped>
.showerror {
  padding-bottom: 20px !important;
}
</style>
