import { InMemoryCache } from '@apollo/client/core';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        boern: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});
