<template>
  <IstModal data-test="dialog-help" title="Hjælp">
    <div v-html="htmlstring"></div>
    <template #footer>
      <ISTButton
        data-test="btn-help-dialog"
        variant="neutral"
        rounded
        elevated
        size="lg"
        @click="close"
        >Luk</ISTButton
      >
    </template>
  </IstModal>
</template>

<script setup lang="ts">
import vnode from './vnode';
import IstModal from '@/components/IstModal.vue';

export interface HelpProps {
  helpId?: string;
}
const htmlstring = ref('');

// PROPS AND EMIT
const emits = defineEmits<(e: 'close', result: boolean) => void>();
const props = withDefaults(defineProps<HelpProps>(), {});

onMounted(async () => {
  console.log(props.helpId);
  // GET request using axios with async/await
  const module = await import(`./../help/help${props.helpId}.htm`);
  htmlstring.value = vnode.created(module.render());
});

const close = () => {
  emits('close', false);
};
</script>
