import { LoginOption, MainConfig } from './types';
import { defineStore } from 'pinia';
import { GetEnvironmentQuery } from '@/generated/graphqlUser';
import { GetEnvironment } from '@/graphql/documents/loginqueries/environment.graphql';
import { apolloClient } from '@/graphql/apolloClient';
import * as Sentry from '@sentry/browser';
import { provideApolloClient } from '@vue/apollo-composable';

const initialMainConfig: MainConfig = {
  ENVIRONMENTNAME: '',
  KEYCLOAK_CLIENTID: '',
  KEYCLOAK_URL: '',
  KEYCLOAK_REALM: '',
  KEYCLOAK_SUPPORTTOOL_CLIENTID: '',
  KEYCLOAK_SUPPORTTOOL_REALM: '',
  I18N_LOCALE: '',
  I18N_FALLBACK_LOCALE: '',
  APP_API_URL: '',
  GRAPHQL_URL: '',
  urlToHelp: '',
  GRAPHQL_OPTIONS: {},
  GRAPHQL_WS_OPTIONS: { uri: '' },
  CommitHash: '',
  loginOption: '',
};

export const useMainStore = defineStore('main', () => {
  provideApolloClient(apolloClient);

  const mainConfig = reactive<MainConfig>(initialMainConfig);

  // GETTERS
  const getLoginOption = computed(() => {
    return mainConfig.loginOption;
  });
  // ACTIONS
  async function fetchEnvironment() {
    const result = await apolloClient.query<GetEnvironmentQuery>({
      query: GetEnvironment,
      context: { clientName: 'userapi' },
    });
    if (result.data) {
      const en = result.data.environment;
      if (mainConfig.ENVIRONMENTNAME !== en.branch) {
        mainConfig.ENVIRONMENTNAME = en.branch;
        Sentry.configureScope(function (scope) {
          scope.addEventProcessor(function (event) {
            return new Promise(function (resolve) {
              event.environment = en.branch;
              return resolve(event);
            });
          });
        });
      }
      mainConfig.KEYCLOAK_CLIENTID = en.idpClientId;
      mainConfig.KEYCLOAK_URL = en.idpUrl;
      mainConfig.KEYCLOAK_REALM = en.idpRealm;
      mainConfig.KEYCLOAK_SUPPORTTOOL_CLIENTID = en.supportToolIdPClientId;
      mainConfig.KEYCLOAK_SUPPORTTOOL_REALM = en.supportToolIdPRealm;
      mainConfig.CommitHash = en.commitHash || '';
    }
  }
  function setLoginOption(option: LoginOption) {
    mainConfig.loginOption = option;
  }
  return { mainConfig, fetchEnvironment, getLoginOption, setLoginOption };
});
