import { IModule } from '../security/models/module-model';

export default <IModule>{
  routes: [
    {
      path: '/rapporter',
      name: 'rapporter',
      meta: { requiresAuth: true, title: 'Rapporter' },
      component: () => import('./rapportView.vue'),
    },
  ],
  menu: [
    {
      title: 'Rapporter',
      name: 'rapporter',
      entryPointUrl: '/rapporter',
      iconName: 'mdi-file-chart',
      priority: 101,
      userRightNames: ['menupunktrapporter'],
    },
  ],
};
